import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import PageLayout from '../components/common/page-layout/page-layout';
import ConditionsMembership from '../components/content/conditions-membership/conditions-membership';
import SectionIntended from '../components/templates/section-intended/section-intended';

const PrivacyPage = () => {
    const intl = useIntl();

    return (
        <PageLayout title={intl.formatMessage({ id: 'pages.conditionsMembership.title' })}>
            <SectionIntended>
                <h3 className="color-white" style={{ marginTop: '10px', hyphens: 'auto' }}>
                    <FormattedMessage id="pages.conditionsMembership.headline" />
                </h3>
                <ConditionsMembership />
            </SectionIntended>
        </PageLayout>
    );
};

export default PrivacyPage;
